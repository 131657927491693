@tailwind base;
@tailwind components;
@tailwind utilities;

/* *{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

.container{
  text-align: center;

}
.section-left h1{
  margin-top: 10px;

}
.btn{
  color:  red;
  background-color: blue;
  width: fit-content;
  height: fit-content;
  text-align: center;
  
  justify-content: center;
  align-items: center;
} */





